// pages/_app.tsx
import { useEffect } from 'react';
import { SessionProvider, useSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { createHmac } from 'crypto';


export const IntercomInitializer = () => {
  const { data: session, status } = useSession();

  useEffect(() => {
    // Function to load Intercom script
    const loadIntercom = () => {
      const w = window as any;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i: any = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args: any) {
          i.q.push(args);
        };
        w.Intercom = i;
        const l = function () {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/i2h8jqqj';
          const x = d.getElementsByTagName('script')[0];
          x.parentNode?.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    };

    const secretKey = 'Y-LHsQZv_qt13LPNx0KGfmTfPJswDNnJ70GQSiMp'; // IMPORTANT: your web Identity Verification secret key - keep it safe!
    let userIdentifier: any = session?.user?.id ?? "team@workramen.com"; // IMPORTANT: the email for your user

    const hash = createHmac('sha256', secretKey).update(userIdentifier).digest('hex');

    const data = session?.user?.id ? {
      app_id: 'i2h8jqqj',
      name: session?.user?.name, // Full name
      email: session?.user?.email, // Email address
      user_id: session?.user?.id, // User ID from your authentication provider
      user_hash: hash
    } : {
      app_id: 'i2h8jqqj',
    }

    // console.log(data);
    // Initialize Intercom with user info
    const initializeIntercom = () => {
      (window as any).Intercom('boot', data);
      (window as any).Intercom('hide');
    };

    // Load Intercom script
    loadIntercom();

    // Initialize Intercom when session is available
    if (status === 'authenticated' && session?.user) {
      initializeIntercom();
    }

    // Cleanup function to shutdown Intercom on component unmount
    return () => {
      (window as any).Intercom('shutdown');
    };
  }, [session, status]);

  return null;
};
